
import { mapGetters } from 'vuex'
import 'swiper/swiper.min.css'
import { Currency } from '~/plugins/Currency'
import Types from '../../static/database/Types.json'
import VideoPlayer from '../VideoPlayer/videojs.vue'
import Modal from '@/components/Shared/Modal/index.vue'
import { convertToSlug } from '~/helpers/generate-slug'
export default {
  components: { VideoPlayer, Modal },
  computed: {
    ...mapGetters({
      getUserIP: 'user/getUserIP',
      getShowcaseProducts: 'showcaseProduct/getShowcaseProducts',
    }),
  },
  data() {
    const vm = this
    return {
      activeIndex: 0,
      videoOptions: {
        poster: this.$store.state.settings.bannerImg,
        live: false,
        autoplay: true,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: '',
            type: 'video/mp4',
          },
        ],
      },
      showVideo: false,
      showYoutube: '',
      product: {},
      intrvl: -1,
      started: false,
      remain: {
        day: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },

      swiperOption: {
        autoplay: 5000,
        slidesPerView: 1,
        initialSlide: 0,
        onSlideChangeEnd: (swiper) => {
          // can do something
          if (vm) vm.activeIndex = swiper.activeIndex
        },
      },
    }
  },
  watch: {
    getShowcaseProducts: {
      deep: true,
      handler() {
        if (this.getShowcaseProducts.length > 0) this.SET_REMAIN_DATE_OF_EVENT()
      },
    },
  },
  methods: {
    Currency,
    convertToSlug,
    formatter(date) {
      return this.$date.format(date)
    },
    SET_ACTIVE_PRODUCT(item) {
      if (item === undefined) return
      this.videoOptions.sources[0].src = ''
      this.showYoutube = ''
      this.product = item
      if (item.videoPath.includes('www.youtube.com/watch?v=')) {
        this.showYoutube = item.videoPath.split('www.youtube.com/watch?v=')[1]
      } else {
        this.videoOptions.sources[0].src = this.$api.path(item.videoPath)
      }
      this.showVideo = true
    },
    SHOW_DETAIL_BUTTON(type) {
      if (type === 1) return 'goToCourse'
      else if (type === 2) return 'goToBook'
      else if (type === 3) return 'goToEvent'
      else if (type === 4) return 'goToSeance'
      else if (type === 5) return 'goToBlog'
      else return 'showCourse'
    },
    SET_TYPE(type) {
      return Types.find((t) => t.type === type).to
    },
    SET_TYPE_NAME(type) {
      return Types.find((t) => t.type === type).name
    },
    FILL_ZERO(time) {
      if (time < 10) return `0${time}`
      else return time
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
        ].join(':')
      )
    },
    SET_INTERVAL(closestEvent, index) {
      const seconds = 1000
      const minutes = seconds * 60
      const hours = minutes * 60
      const days = hours * 24
      const event = closestEvent
      const vm = this
      const intrvl = setInterval(() => {
        const d = new Date().getTime()
        const ed = this.$date.getTimeWithoutOffset(event.date)
        const remain = ed - d
        if (remain < 0) {
          vm.$store.commit('showcaseProduct/SET_REMOVE_EVENT_ITEM', {
            index,
            id: vm.product.id,
          })
          clearInterval(vm.getShowcaseProducts[index].intrvl)
        }
        vm.$store.commit('showcaseProduct/SET_REMAIN_OF_EVENT', {
          index,
          day: Math.floor(remain / days),
          hours: Math.floor((remain % days) / hours),
          minutes: Math.floor((remain % hours) / minutes),
          seconds: Math.floor((remain % minutes) / seconds),
        })
      }, 1000)
      vm.$store.commit('showcaseProduct/SET_INTERVAL_OF_EVENT', {
        index,
        intrvl,
      })
    },
    SET_REMAIN_DATE_OF_EVENT() {
      if (this.started) return
      this.getShowcaseProducts.forEach((p, i) => {
        this.started = true
        if (p.closestEvent !== null) this.SET_INTERVAL(p.closestEvent, i)
      })
    },
  },
  beforeDestroy() {
    this.getShowcaseProducts.forEach((p, i) => {
      if (p.intrvl !== -1) clearInterval(p.intrvl)
    })
  },
  mounted() {
    if (process.client)
      this.$store.dispatch('showcaseProduct/GET_SHOWCASE_PRODUCTS', {
        currency: this.getUserIP.currency,
      })
    if (this.getShowcaseProducts.length > 0) this.SET_REMAIN_DATE_OF_EVENT()
  },
}

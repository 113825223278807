
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10) // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = sec_num - hours * 3600 - minutes * 60
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  if (hours === '00') return minutes + ':' + seconds
  else return hours + ':' + minutes + ':' + seconds
}
import Modal from '@/components/Shared/Modal/index.vue'
export default {
  data() {
    return {
      showAlertModal: {
        btnText: '',
        status: false,
        body: '',
        title: '',
        ok: () => false,
      },
      safari: false,
      ProgressHeight: 0.6,
      video: {},
      percent: 0,
      controlBar: {
        isWaiting: true,
        status: false,
        duration: 0,
        currentTime: 0,
        loadedProgressBar: 0,
        onPlay: false,
        volume: false,
        onShow: true,
        showTimeout: -1,
        updateDurationInterval: -1,
      },
    }
  },
  components: { Modal },
  props: {
    quality: {
      default: 720,
    },
    src: {
      default: '',
    },
    poster: {
      default: '',
    },
    loading: {
      default: true,
    },
    withInfo: {
      default: false,
      type: Boolean,
    },
    single: {
      default: false,
      type: Boolean,
    },
    videoStream: {
      default: () => {
        return {
          nextVideoId: -1,
          prevVideoId: 0,
          isFinished: 1,
          description: '',
          maxWatchedDuration: 0,
          watchedDuration: 0,
          sectionId: 1,
        }
      },
    },
    course: {
      default: () => {
        return {
          title: '',
        }
      },
    },
  },
  beforeDestroy() {
    if (this.controlBar.updateDurationInterval !== -1)
      clearInterval(this.updateDurationInterval)
  },
  methods: {
    SET_IF_SAFARI(data) {
      setTimeout(() => {
        if (this.safari) this.controlBar.onPlay = data
      }, 2500)
    },
    SET_BUFFERED_PERCENTAGE(e) {
      // video current time set edilir.
      this.controlBar.currentTime = e.target.currentTime
      // buffer percantage hesaplanır.
      var buffered = e.target.buffered.end(0)
      var duration = e.target.duration
      var currentTime = e.target.currentTime
      this.percent = (currentTime * 100) / duration
      // process loaded bar yüzdelik hesaplanır.
      this.controlBar.loadedProgressBar =
        (buffered / duration) * 100 - this.percent
      this.$emit('update:time', Math.floor(e.target.currentTime))
      if (e.target.currentTime > this.videoStream.maxWatchedDuration)
        this.$emit('update:maxWatched', Math.floor(e.target.currentTime))
    },
    SET_SHOW_CONTROL_BAR() {
      this.controlBar.onShow = true
      if (this.controlBar.showTimeout !== -1)
        clearTimeout(this.controlBar.showTimeout)
      this.controlBar.showTimeout = setTimeout(() => {
        this.controlBar.onShow = false
      }, 3000)
    },
    SET_VOLUME_OUTSIDE() {
      this.controlBar.volume = false
    },
    SET_VIDEO_FULLSCREEN() {
      {
        let elem = document.querySelector('#video-container')

        if (!document.fullscreenElement) {
          elem.webkitRequestFullScreen()
        } else {
          document.exitFullscreen()
          document.webkitCancelFullScreen()
          // document.mozCancelFullScreen()
          document.cancelFullScreen()
        }
      }
    },
    SET_UNDO_REDO_CURRENT_TIME(time) {
      this.video.currentTime = this.video.currentTime + time
    },
    SET_CURRENT_TIME(time) {
      return String(Math.floor(time)).toHHMMSS()
    },
    SET_PROGRESS_CLICK(event) {
      let outerDiv = document.getElementById('progress-div')
      const width = event.clientX - outerDiv.offsetLeft
      const percent = (width * 100) / outerDiv.clientWidth
      const duration = (percent * this.controlBar.duration) / 100
      if (
        duration > this.videoStream.maxWatchedDuration &&
        this.videoStream.isFinished === 0
      ) {
        this.showAlertModal = {
          status: true,
          btnText: 'Anladım',
          body: 'Bu videoyu daha önce bitirmediğiniz için ileriye saramazsınız.',
          title: 'Uyarı',
          ok: () => (this.showAlertModal.status = false),
        }
      } else {
        this.video.currentTime = duration
      }
    },
    SET_VOLUME_CLICK(event) {
      this.controlBar.volume = true
      if (event > -1) {
        if (event === 0) {
          this.video.volume = 1
        } else this.video.volume = 0
        return
      }
      const height = 100 - event.layerY
      this.video.volume = height / 100
    },
    // video işlemleri için *required
    LISTEN_VIDEO() {
      const vm = this
      this.video = document.getElementById('video')
      this.video.addEventListener('loadedmetadata', (event) => {
        if (!isNaN(vm.video.duration)) {
          vm.controlBar.status = true
          vm.controlBar.duration = vm.video.duration
          vm.SET_INIT_VIDEO_STREAM()
          vm.video.onended = function () {
            vm.controlBar.onPlay = false
            vm.SET_UPDATE_FINISHED_VIDEO_TO_API()
          }
          vm.video.addEventListener('waiting', () => {
            vm.controlBar.isWaiting = true
          })
          vm.video.addEventListener('playing', () => {
            vm.controlBar.isWaiting = false
          })
          vm.video.onplay = (e) => {
            vm.controlBar.onPlay = true
            vm.SET_UPDATE_DURATION_TO_API()
          }
          vm.video.onpause = (e) => {
            vm.controlBar.onPlay = false
            if (vm.controlBar.updateDurationInterval !== -1) {
              clearInterval(vm.controlBar.updateDurationInterval)
            }
          }
          vm.video.ontimeupdate = vm.SET_BUFFERED_PERCENTAGE
          //Update your progressbar DOM here
          vm.video.onseeking = vm.SET_SEEKING_VIDEO
        } else {
          vm.$router.push(this.localePath('/error'))
        }
      })
      if (navigator.userAgent.includes('Safari')) this.safari = true
    },
    // Buradan Aşağısı methodlar kişiselleştirilmek içindir.
    // Video kayıtlı durationu varsa set et.
    SET_SEEKING_VIDEO() {
      if (this.single) return
      this.controlBar.loadedProgressBar = 0
      this.$api
        .put('UserCourses', {
          videoId: Number(this.$route.params.videoId),
          isFinished: 0, // video aktif izlendiği için 0 gönderilir.
          watchedDuration: Math.floor(this.video.currentTime),
        })
        .then((response) => {
          if (response.message === 'OK') {
            this.$emit('update:time', Math.floor(this.video.currentTime))
          }
        })
    },
    SET_INIT_VIDEO_STREAM() {
      if (this.controlBar.duration - 10 > this.videoStream.watchedDuration)
        this.video.currentTime = this.videoStream.watchedDuration
    },
    // 25sn de bir userın durationu kayıt edilir.
    SET_UPDATE_DURATION_TO_API() {
      if (this.single) return
      this.controlBar.updateDurationInterval = setInterval(async () => {
        await this.$api.put('UserCourses', {
          videoId: Number(this.$route.params.videoId),
          isFinished: 0, // video aktif izlendiği için 0 gönderilir.
          watchedDuration: Math.floor(this.video.currentTime),
        })
      }, 10000)
    },
    // video bitirildiğinde gönderilir.
    async SET_UPDATE_FINISHED_VIDEO_TO_API() {
      if (this.single) return
      const response = await this.$api.put('UserCourses', {
        videoId: Number(this.$route.params.videoId),
        isFinished: 1,
        watchedDuration: Math.floor(this.video.currentTime),
      })
      if (response.message === 'OK') {
        this.$store.commit('coursesVideoList/SET_IS_FINISHED_VIDEO', {
          sectionId: this.videoStream.sectionId,
          videoId: Number(this.$route.params.videoId),
          courseId: Number(this.$route.params.courseId),
        })
        this.$emit('success:videoStreamIsFinished', 1)
        if (response.data.code === 10) {
          this.showAlertModal = {
            status: true,
            btnText: 'Git',
            body: 'Tebrikler! Bu eğitim bölümünü tamamladınız. Bir sonraki videoya yönlendirileceksiniz.',
            title: 'Tebrikler',
            ok: () =>
              this.$router.replace({
                params: { videoId: response.data.videoId },
              }),
          }
        } else if (response.data.code === 20) {
          this.showAlertModal = {
            status: true,
            btnText: 'Git',
            body: 'Tebrikler! Bu eğitim bölümünü tamamladınız. Yeni eğitim videonuz hesabınıza yüklendi. Yeni videoya yönlendirileceksiniz.',
            title: 'Tebrikler',
            ok: () =>
              (location.href = `/course/${this.$route.params.courseId}/learn/${response.data.videoId}}`),
          }
        } else if (response.data.code === 30) {
          this.showAlertModal = {
            status: true,
            btnText: 'Anladım',
            body: response.data.message,
            title: 'Tebrikler',
            ok: () =>
              this.$router.replace({
                params: { videoId: response.data.videoId },
              }),
          }
        } else if (response.data.code === 40) {
          this.showAlertModal = {
            status: true,
            btnText: 'Anladım',
            body: 'Tebrikler! Kursumu tamamladın. Dilersen eski videoları tekrardan izleyebilirsin. Teşekkürler!',
            title: 'Tebrikler',
            ok: () => (this.showAlertModal.status = false),
          }
        } else {
          this.showAlertModal = {
            status: true,
            btnText: 'Sayfamı Yenile',
            body: 'Bir problem ile karşılaştık. Lütfen tekrar deneyiniz!',
            title: 'Hata',
            ok: () => this.$router.go(0),
          }
        }
      }
    },
  },
  mounted() {
    this.LISTEN_VIDEO()
  },
}
